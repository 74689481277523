import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";

const PrivateRoute = ({ children, adminOnly = false }) => {
    const token = localStorage.getItem('token');
    const { userId } = useParams();

    if (!token) {
        return <Navigate to="/login" />;
    }

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
            localStorage.removeItem('token');
            return <Navigate to="/login" />;
        }

        if (adminOnly && decodedToken.role !== '[ROLE_ADMIN]') {
            return <Navigate to="/unauthorized" />;
        }

        const isUserAuthorized = decodedToken.userId.toString() !== userId && decodedToken.role !== '[ROLE_ADMIN]';
        if (isUserAuthorized) {
            return <Navigate to="/unauthorized" />;
        }

        return children;
    } catch (error) {
        localStorage.removeItem('token');
        return <Navigate to="/login" />;
    }
};


export default PrivateRoute;
