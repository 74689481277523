import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../styles/NotificationMessage/NotificationMessage.css'

const NotificationMessage = ({ message, type, onClose }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                onClose();
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    return (
        <div className={`notification-message ${type}`}>
            {message}
        </div>
    );
};

NotificationMessage.propTypes = {
    message: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error']),
    onClose: PropTypes.func.isRequired,
};

export default NotificationMessage;
