import React, {useState} from 'react';
import '../../styles/LoginSignup.css'

import {useNavigate} from "react-router-dom";
import email_icon from "../../assets/email.png";
import password_icon from "../../assets/password.png";
import NotificationMessage from "../NotificationMessage/NotificationMessage";
import {LoginService} from "../../services/LoginService";

const SignUp = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [notification, setNotification] = useState({message: '', type: null});

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            await LoginService.register(username, password);
            setNotification({ message: 'User created successfully!', type: 'success' });
            setUsername('');
            setPassword('');
            navigate('/login');
        } catch (error) {
            setNotification({ message: `Error craeting user: ${error.response.data}`, type: 'error' });
        }
    };

    return (
        <div className='container'>
            <div className='header'>
                <div className='text'>Sign Up</div>
                <div className='underline'></div>
            </div>
            <div className='inputs'>
                <div className="input">
                    <img src={email_icon} alt='email icon'/>
                    <input
                        type='text'
                        placeholder='Username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
            </div>
            <div className='inputs'>
                <div className="input">
                    <img src={password_icon} alt='password icon'/>
                    <input
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </div>
            <div className="submit-container">
                <div className='submit' onClick={handleSignUp}>Sign Up</div>
                <div className='submit gray' onClick={() => {
                    setUsername('');
                    setPassword('');
                    navigate('/login');
                }}>Login
                </div>
            </div>
            <NotificationMessage
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({message: '', type: null})}
            />
        </div>

    );
};

export default SignUp;
