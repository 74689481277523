import React, { useState } from 'react';

const CreateCardForm = ({ userId, onClose, onSubmit }) => {
    const [cardNumber, setCardNumber] = useState('');
    const [currency, setCurrency] = useState('USD');
    const [expiration, setExpiration] = useState('');
    const [cardholderName, setCardholderName] = useState('')
    const [cvv, setCvv] = useState('');

    const formatCardNumber = (value) => {
        return value.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
    };

    const handleCardNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 16) value = value.slice(0, 16);
        setCardNumber(formatCardNumber(value));
    };

    const handleExpirationChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 4) value = value.slice(0, 4);
        if (value.length >= 3) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }
        setExpiration(value);
    };

    const handleCvvChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 3) value = value.slice(0, 3);
        setCvv(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const userCardDto = { cardNumber, currency, expiration, cvv, cardholderName };
        onSubmit(userId, userCardDto);
    };

    return (
        <div className="modal-backdrop">
            <div className="modal">
                <h2>Create Card</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Card Number</label>
                        <input
                            type="text"
                            value={cardNumber}
                            onChange={handleCardNumberChange}
                            placeholder="Enter card number"
                            maxLength={19}
                            pattern="(?:\d{4} ){3}\d{4}"
                            title="Card number must be 16 digits"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Currency</label>
                        <input
                            type="text"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            placeholder="Enter currency"
                            pattern="[A-Za-z]{3,}"
                            title="Currency should be alphabetic characters only (e.g., USD, EUR)"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Expiration (MM/YY)</label>
                        <input
                            type="text"
                            value={expiration}
                            onChange={handleExpirationChange}
                            placeholder="MM/YY"
                            pattern="(0[1-9]|1[0-2])\/[0-9]{2}"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>CVV</label>
                        <input
                            type="text"
                            value={cvv}
                            onChange={handleCvvChange}
                            placeholder="Enter CVV"
                            maxLength={3}
                            pattern="\d{3}"
                            title="CVV must be exactly 3 digits"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Card holder name</label>
                        <input
                            type="text"
                            value={cardholderName}
                            onChange={(e) => {
                                setCardholderName(e.target.value);
                            }}
                            placeholder="Enter name"
                            pattern=".*\S.*"
                            title="Name cannot be empty or consist only of spaces"
                            required
                        />
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="submit-btn">
                            Add Card
                        </button>
                        <button type="button" onClick={onClose} className="cancel-btn">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateCardForm;
