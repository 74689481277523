import React from 'react';
import '../../styles/PdfViewer.css'

const TermsLink = () => {
  return (
    <div className="pdf-viewer">
      <iframe 
        src="/terms.pdf" 
        width="100%" 
        height="100%" 
        title="PDF Viewer" 
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default TermsLink;