// Header.js
import { Link } from "react-router-dom";
import { useAuth } from "../AuthService/AuthProvider";
import '../../styles/Header/Header.css';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from '../../assets/logo.png';

const Header = ({ children }) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleHomePage = () => {
        navigate('/home');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <nav className="navbar">
                <div className="logo">
                    <img onClick={handleHomePage} src={logo}/>
                </div>
                <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
                    {user && user.role === '[ROLE_ADMIN]' && <li><Link to="/dashboard">Users</Link></li>}
                    <li><Link to='/' onClick={handleLogout}>Logout</Link></li>
                </ul>
                <div className="hamburger" onClick={toggleMenu}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </nav>
            <div className="child-content">
                {children}
            </div>
        </>
    );
};

export default Header;
