import React, { useState } from 'react';
import '../../styles/DashboardContent/MoneyTransfer.css';
import NotificationMessage from '../NotificationMessage/NotificationMessage';
import { useAuth } from '../AuthService/AuthProvider';

const MoneyTransferComponent = ({ userHasCard, handleMoneyTransferSubmit, balance }) => {
    const { user } = useAuth();
    const [notification, setNotification] = useState({ message: '', type: null });

    const initialTransferData = {
        destinationBank: '',
        destinationCountry: '',
        accountNumber: '',
        amountToSend: '',
    };

    const [transferData, setTransferData] = useState(initialTransferData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTransferData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (user?.role !== '[ROLE_ADMIN]' && parseFloat(transferData.amountToSend) > balance) {
            setNotification({ message: 'Withdrawal amount exceeds available balance', type: 'error' });
            return;
        }

        handleMoneyTransferSubmit(transferData);
        setTransferData(initialTransferData);
    };


    return (
        <form className="moneyTransferForm" onSubmit={handleSubmit}>
            <h3>Money Transfer</h3>

            <div className="formGroup">
                <label className="label">Destination Bank</label>
                <input
                    type="text"
                    name="destinationBank"
                    value={transferData.destinationBank}
                    onChange={handleInputChange}
                    className="custom-input"
                    placeholder="Bank Name"
                    required
                    pattern="^\S+$"
                    title="Please fill out this field without spaces."
                />
            </div>

            <div className="formGroup">
                <label className="label">Destination Country</label>
                <input
                    type="text"
                    name="destinationCountry"
                    value={transferData.destinationCountry}
                    onChange={handleInputChange}
                    className="custom-input"
                    placeholder="Country"
                    required
                    pattern="^\S+$"
                    title="Please fill out this field without spaces."
                />
            </div>

            <div className="formGroup">
                <label className="label">Account Number</label>
                <input
                    type="text"
                    name="accountNumber"
                    value={transferData.accountNumber}
                    onChange={handleInputChange}
                    className="custom-input"
                    placeholder="Recipient account №"
                    required
                    pattern="^\S+$"
                    title="Please fill out this field without spaces."
                />
            </div>

            <div className="formGroup">
                <label className="label">
                    {user?.role === '[ROLE_ADMIN]' ? 'Amount to Deposit' : 'Amount to Withdraw'}
                </label>
                <input
                    type="number"
                    name="amountToSend"
                    value={transferData.amountToSend}
                    onChange={handleInputChange}
                    className="custom-input"
                    placeholder={`Amount in ${user?.role === '[ROLE_ADMIN]' ? 'USD to deposit' : 'USD to withdraw'}`}
                    required
                    min="1"
                />
            </div>

            {user?.role !== '[ROLE_ADMIN]' && (
                <>
                    <div className="formGroup">
                        <label className="label">BIC (Optional)</label>
                        <input
                            type="text"
                            name="bic"
                            value={transferData.bic}
                            onChange={handleInputChange}
                            className="custom-input"
                            placeholder="BIC Code (8-11 characters)"
                            pattern=".{8,11}"
                            title="BIC must be 8 to 11 characters long"
                        />
                    </div>

                    <div className="formGroup">
                        <label className="label">SWIFT (Optional)</label>
                        <input
                            type="text"
                            name="swift"
                            value={transferData.swift}
                            onChange={handleInputChange}
                            className="custom-input"
                            placeholder="SWIFT Code (8-11 characters)"
                            pattern=".{8,11}"
                            title="SWIFT must be 8 to 11 characters long"
                        />
                    </div>
                </>
            )}

            <button
                disabled={!userHasCard}
                type="submit"
                className="submitButton"
                title={!userHasCard ? 'Please create a card before making a money transfer' : ''}
            >
                {user?.role === '[ROLE_ADMIN]' ? 'Deposit Now' : 'Withdraw Now'}
            </button>

            <NotificationMessage
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({message: '', type: null})}
            />
        </form>
    );
};

export default MoneyTransferComponent;
