import React, {useState} from 'react';
import '../../styles/LoginSignup.css'

import email_icon from '../../assets/email.png'
import password_icon from '../../assets/password.png'
import {useNavigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {useAuth} from "../AuthService/AuthProvider";
import {LoginService} from "../../services/LoginService";
import NotificationMessage from "../NotificationMessage/NotificationMessage";

const Login = () => {
    const navigate = useNavigate();
    const {setUser} = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [notification, setNotification] = useState({message: '', type: null});

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await LoginService.login(username, password);
            const token = data.token;

            localStorage.setItem('token', token);

            const decodedToken = jwtDecode(token);
            const role = decodedToken.role;
            const userId = decodedToken.userId;

            setUser({
                id: userId,
                role: role,
            });

            if (role === '[ROLE_ADMIN]') {
                navigate('/dashboard');
            } else {
                navigate(`/user/${userId}`, {state: {hasCard: true}});
            }
        } catch (error) {
            setNotification({message: `Failed to login: ${error.response.data}`, type: 'error'});
        }
    };

    return (
        <div className='container'>
            <div className='header'>
                <div className='text'>Login</div>
                <div className='underline'></div>
            </div>
            <div className='inputs'>
                <div className="input">
                    <img src={email_icon} alt='email icon'/>
                    <input
                        type='text'
                        placeholder='Username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
            </div>
            <div className='inputs'>
                <div className="input">
                    <img src={password_icon} alt='password icon'/>
                    <input
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </div>
            <div className="submit-container">
                <div className='submit purple' onClick={handleSubmit}>Login</div>
                <div className='submit gray' onClick={() => {
                    setUsername('');
                    setPassword('');
                    navigate('/register');
                }}>Sign Up
                </div>
            </div>
            <NotificationMessage
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({message: '', type: null})}
            />
        </div>
    );
};

export default Login;

