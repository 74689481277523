import axios from 'axios';

const transactionApiClient = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

transactionApiClient.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('token');

    if (
        accessToken &&
        !config.url.includes('/auth/login') &&
        !config.url.includes('/auth/register')
    ) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default transactionApiClient;
