import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import TransactionComponent from './TransactionComponent';
import MoneyTransferComponent from './MoneyTransferComponent';
import CardComponent from './CardComponent';
import '../../styles/DashboardContent/UserDetails.css';
import {useAuth} from "../AuthService/AuthProvider";
import NotificationMessage from "../NotificationMessage/NotificationMessage";
import {UserCardService} from "../../services/UserCardService";
import {UserService} from "../../services/UserService";
import {TransactionService} from "../../services/TransactionService";
import moment from 'moment';

const UserDetails = () => {
    const { user } = useAuth();
    const { userId } = useParams();
    const { state } = useLocation();
    const [card, setCard] = useState({ cardNumber: '', currency: '', expiration: '', cvv: '', balance: 0 });
    const [notification, setNotification] = useState({ message: '', type: null });
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        UserService.getUserById(userId).then(response => {
            if (response.card) {
                setCard(response.card);
            } else {
                setCard({ cardNumber: '', currency: '', expiration: '', cvv: '', balance: 0 });
            }
        }).catch(error => {
            setNotification({ message: `Failed to load details: ${error.response.data}`, type: 'error' });
        });

        TransactionService.getAllTransactionsByUserId(userId)
            .then(response => setTransactions(response))
            .catch(error => {
                setNotification({ message: `Failed to load transactions: ${error.response.data}`, type: 'error' });
            });

    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCard(prevCard => ({
            ...prevCard,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        const cardData = {
            ...card,
            cardNumber: card.cardNumber.replace(/\s/g, ''),
        };
        UserCardService.updateCard(userId, cardData)
            .then(() => {
                setNotification({ message: 'Card updated successfully!', type: 'success' });
            })
            .catch(error => {
                console.log(error)
                setNotification({ message: `Card Update Failed: ${error.response.data}`, type: 'error' });
            });
    };

    const handleMoneyTransferSubmit = (transferData) => {
        const dateTime = moment().format('YYYY-MM-DDTHH:mm:ss');
        const updatedTransferData = {...transferData, dateTime}
        const apiCall = user?.role === '[ROLE_ADMIN]'
            ? TransactionService.createDeposit
            : TransactionService.createWithdraw;
        apiCall(userId, updatedTransferData)
            .then((newTransaction) => {
                setTransactions(prevTransactions => [ newTransaction, ...prevTransactions]);
                setNotification({ message: 'Transaction created successfully', type: 'success' });
            })
            .catch((error) => {
                setNotification({ message: `Transaction failed: ${error.response.data}`, type: 'error' });
            });
    };

    const handleStatusChange = (transactionId, newStatus) => {
        TransactionService.updateTransactionStatus(userId, transactionId, newStatus)
            .then(() => {
                setTransactions(prevTransactions =>
                    prevTransactions.map(transaction =>
                        transaction.id === transactionId
                            ? { ...transaction, status: newStatus }
                            : transaction
                    )
                );
                setNotification({ message: 'Transaction status updated successfully!', type: 'success' });

                UserService.getUserById(userId)
                    .then(response => {
                        setCard(response.card);
                    })
                    .catch(error => {
                        setNotification({ message: `Failed to update card balance: ${error.response.data}`, type: 'error' });
                    });
            })
            .catch(error => {
                setNotification({ message: `Failed to update status: ${error.response.data}`, type: 'error' });
            });
    };

    return (
        <div className="user-details-container">
            <header className="header"></header>

            <div className="main-content">
                <div className="left-section">
                    <CardComponent
                        userHasCard={state?.hasCard ?? false}
                        card={card}
                        handleInputChange={handleInputChange}
                        handleSubmit={handleSubmit}
                        isAdmin={user?.role === '[ROLE_ADMIN]'}
                    />
                </div>

                <div className="right-section">
                    <div className="transaction-component">
                        <TransactionComponent
                            transactions={transactions}
                            handleStatusChange={handleStatusChange}
                        />
                    </div>
                    <div className="money-transfer-component">
                        <MoneyTransferComponent
                            handleMoneyTransferSubmit={handleMoneyTransferSubmit}
                            userHasCard={state?.hasCard ?? false}
                            balance={card.balance || 0}
                        />
                    </div>
                </div>

                <NotificationMessage
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({ message: '', type: null })}
                />
            </div>
        </div>
    );
};


export default UserDetails;
