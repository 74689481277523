import transactionApiClient from "./TransactionApiClient";

const API_URL = process.env.REACT_APP_API_URL;

export const TransactionService = {
    async createDeposit(userId, adminTransactionRequestDto) {
        const response = await transactionApiClient.post(
            `${API_URL}/users/${userId}/transactions/deposit`,
            adminTransactionRequestDto
        );
        return response.data;
    },

    async createWithdraw(userId, userTransactionRequestDto) {
        const response = await transactionApiClient.post(
            `${API_URL}/users/${userId}/transactions/withdraw`,
            userTransactionRequestDto
        );
        return response.data;
    },

    async getAllTransactionsByUserId(userId) {
        const response = await transactionApiClient.get(
            `${API_URL}/users/${userId}/transactions`
        );
        return response.data;
    },

    async updateTransactionStatus(userId, transactionId, transactionStatusUpdateDto) {
        const response = await transactionApiClient.patch(
            `${API_URL}/users/${userId}/transactions/${transactionId}/status`, {
                "status": transactionStatusUpdateDto
            }
        );
        return response.data;
    }
};
