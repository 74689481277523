import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FaCheck, FaTimes, FaEdit, FaTrashAlt} from 'react-icons/fa';
import '../../styles/UserList.css';
import {CreditCard} from "@mui/icons-material";
import UserFormModal from "../CreateForm/UserFormModal";
import CreateCardForm from "../CreateForm/CreateCardForm";
import NotificationMessage from "../NotificationMessage/NotificationMessage";
import {UserCardService} from "../../services/UserCardService";
import {UserService} from "../../services/UserService";

const UserList = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [notification, setNotification] = useState({ message: '', type: null });
    const [showModal, setShowModal] = useState(false);
    const [showCreateCardDialog, setShowCreateCardDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleCloseModalDialog = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

    const handleCloseCreateCardDialog = () => {
        setShowCreateCardDialog(false);
        setSelectedUser(null);
    };

    useEffect(() => {
        UserService.getAllUsers().then(response => {
            setUsers(response);
        }).catch(error => {
            setNotification({ message: `Failed to load users: ${error.response.data}`, type: 'error' });
        });
    }, []);

    const handleRowClick = (userId) => {
        const user = users.find((user) => user.id === userId);
        if (!user?.hasCard) {
            return;
        }
        if(user?.hasCard) {
            navigate(`/user/${userId}`, { state: { hasCard: user?.hasCard ?? false } });
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleAddUser = () => {
        setShowModal(true);
    };

    const handleCreateUserCard = (user) => {
        setSelectedUser(user);
        setShowCreateCardDialog(true);
    };

    const handleDelete = (userId) => {
        UserService.deleteUser(userId).then(() => {
            setUsers((prevUsers) => prevUsers.filter(user => user.id !== userId));
            setNotification({ message: 'User deleted successfully!', type: 'success' });
        }).catch((error) => {
            setNotification({ message: `Failed to delete user: ${error.response.data}`, type: 'error' });
        });
    };

    const handleCreateCardForUser = (userId, userCardDto) => {
        const cardData = {
            ...userCardDto,
            cardNumber: userCardDto.cardNumber.replace(/\s/g, '')
        };
        setSelectedUser(null);
        UserCardService.addCardForUser(selectedUser.id, cardData).then((response) => {
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === selectedUser.id ? { ...user, hasCard: true } : user
                )
            );
            setShowCreateCardDialog(false);
            setNotification({ message: 'Card created successfully!', type: 'success' });
        }).catch((error) => {
            setNotification({ message: `Failed to create card: ${error.response.data}`, type: 'error' });
        });
    };

    const handleModalSubmit = (user) => {
        if (user.id) {
            updateUser(user);
        } else {
            addUser(user);
        }
        setShowModal(false);
        setSelectedUser(null);
    };

    const updateUser = (user) => {
        UserService.updateUser(user.id, user.username, user.name)
            .then((updatedUser) => {
                setUsers((prevUsers) =>
                    prevUsers.map((u) => (u.id === updatedUser.id ? updatedUser : u))
                );
                setNotification({ message: 'User updated successfully!', type: 'success' });
            })
            .catch((error) => {
                setNotification({ message: `Failed to update user: ${error.response.data}`, type: 'error' });
            });
    };

    const addUser = (user) => {
        UserService.addUser(user.username, user.name)
            .then((newUser) => {
                setUsers((prevUsers) => [...prevUsers, newUser]);
                setNotification({ message: 'User created successfully!', type: 'success' });
            })
            .catch((error) => {
                setNotification({ message: `Failed to create user: ${error.response.data}`, type: 'error' });
            });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm)
    );

    return (
        <div className="table-container">
            <div className="header-container">
                <h1>Users list</h1>
                <div className="header-actions">
                    <input
                        type="text"
                        placeholder="Search by name"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <button className="add-user-btn" onClick={handleAddUser}>
                        Add User
                    </button>
                </div>
            </div>
            <div className="table-responsive">
                <table>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Registered</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredUsers.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => handleRowClick(item.id)}
                            className={`clickable-row ${!item.hasCard ? 'disabled-row' : ''}`}
                            title={!item.hasCard ? 'Please create a card to view this user' : ''}
                        >
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.username}</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                {item.registered ? <FaCheck color="green" /> : <FaTimes color="red" />}
                            </td>
                            <td>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(item);
                                    }}
                                    className="edit-btn"
                                >
                                    <FaEdit />
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(item.id);
                                    }}
                                    className="delete-btn"
                                >
                                    <FaTrashAlt />
                                </button>
                                {!item.hasCard && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCreateUserCard(item);
                                        }}
                                        className="create-card-btn"
                                    >
                                        <CreditCard />
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {showModal && (
                <UserFormModal
                    user={selectedUser}
                    onClose={handleCloseModalDialog}
                    onSubmit={handleModalSubmit}
                />
            )}
            {showCreateCardDialog && (
                <CreateCardForm
                    onClose={handleCloseCreateCardDialog}
                    onSubmit={handleCreateCardForUser}
                />
            )}

            <NotificationMessage
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({message: '', type: null})}
            />
        </div>
    );
};

export default UserList;