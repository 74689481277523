import React, {useEffect} from 'react';
import '../../styles/DashboardContent/CardForm.css';

const CardForm = ({card, handleInputChange, handleSubmit, isAdmin, userHasCard}) => {
    const formatCardNumber = (value) => {
        return value.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
    };

    useEffect(() => {
        if (card.cardNumber) {
            const formattedCardNumber = formatCardNumber(card.cardNumber.replace(/\D/g, ''));
            handleInputChange({target: {name: 'cardNumber', value: formattedCardNumber}});
        }
    }, []);

    const handleCardNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');

        if (value.length > 16) value = value.slice(0, 16);

        const formattedValue = formatCardNumber(value);
        handleInputChange({target: {name: 'cardNumber', value: formattedValue}});
    };

    const handleExpirationChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 4) value = value.slice(0, 4);
        if (value.length >= 3) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }
        handleInputChange({target: {name: 'expiration', value}});
    };

    const handleCvvChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 3) value = value.slice(0, 3);
        handleInputChange({target: {name: 'cvv', value}});
    };

    const handleCardholderNameChange = (e) => {
        let value = e.target.value;

        handleInputChange({target: {name: 'cardholderName', value: value}});
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(card);
    };

    return (
        <>
            {isAdmin && (
                <form className="cardForm" onSubmit={handleFormSubmit}>
                    <div className="formGroup">
                        <label className="label">Card Number</label>
                        <input
                            type="text"
                            name="cardNumber"
                            value={formatCardNumber(card.cardNumber)}
                            onChange={handleCardNumberChange}
                            className="custom-input"
                            pattern="(?:\d{4} ){3}\d{4}"
                            title="Card number must be 16 digits"
                            placeholder="1234 5678 9123 4567"
                            required
                        />
                    </div>
                    <div className="formGroup">
                        <label className="label">Expiration Date</label>
                        <input
                            type="text"
                            name="expiration"
                            value={card.expiration}
                            onChange={handleExpirationChange}
                            className="custom-input"
                            pattern="(0[1-9]|1[0-2])\/[0-9]{2}"
                            placeholder="MM/YY"
                            required
                        />
                    </div>
                    <div className="formGroup">
                        <label className="label">CVV</label>
                        <input
                            type="text"
                            name="cvv"
                            value={card.cvv}
                            onChange={handleCvvChange}
                            className="custom-input"
                            pattern="\d{3}"
                            title="CVV must be exactly 3 digits"
                            placeholder="123"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="label">Card holder name</label>
                        <input
                            type="text"
                            value={card.cardholderName || ''}
                            onChange={handleCardholderNameChange}
                            className="custom-input"
                            placeholder="Enter name"
                            pattern=".*\S.*"
                            title="Name cannot be empty or consist only of spaces"
                            required
                        />
                    </div>
                    <button
                        disabled={!userHasCard}
                        type="submit"
                        className="submitButton"
                        title={!userHasCard ? "Please create card before modifying it" : ""}
                    >Save Changes
                    </button>
                </form>
            )}
        </>
    );
};

export default CardForm;
