import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CardForm from './CardForm';

const CardComponent = ({ card, handleInputChange, handleSubmit, isAdmin, userHasCard }) => {
    const balanceStyle = {
        backgroundColor: '#f5f5f5',
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        textAlign: 'center',
        fontWeight: 'bold',
        width: '200px',
        margin: '10px auto',
        wordWrap: 'break-word',
    };

    const formatBalance = (balance, currency) => {
        if (!balance) return `0 ${currency}`;

        const formattedBalance = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 0,
        }).format(balance);

        return `${formattedBalance} ${currency}`;
    };
    return (
        <>
            <Cards
                name={card.cardholderName || ''}
                number={card.cardNumber || ''}
                expiry={card.expiration || ''}
                cvc={card.cvv || ''}
            />
            <div style={balanceStyle} className="balance-info">
                <p><strong>Balance:</strong> {formatBalance(card.balance, card.currency)}</p>
            </div>
            <CardForm
                userHasCard={userHasCard}
                card={card}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                isAdmin={isAdmin}
            />
        </>
    );
};
export default CardComponent;
