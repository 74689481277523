import transactionApiClient from "./TransactionApiClient";

const API_URL = process.env.REACT_APP_API_URL;

export const LoginService = {
    async login(username, password) {
        const response = await transactionApiClient.post(`${API_URL}/auth/login`, {
            username,
            password,
        });
        return response.data;
    },

    async register(username, password) {
        const response = await transactionApiClient.post(`${API_URL}/auth/register`, {
            username,
            password,
        });
        return response.data;
    },
};
