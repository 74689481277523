import transactionApiClient from "./TransactionApiClient";

const API_URL = process.env.REACT_APP_API_URL;

export const UserCardService = {
    async addCardForUser(userId, UserCardDto) {
        const response = await transactionApiClient.post(`${API_URL}/users/${userId}/card`, UserCardDto);
        return response.data;
    },

    async updateCard(id, card) {
        const response = await transactionApiClient.put(`${API_URL}/users/${id}/card`, card);
        return response.data;
    },
};
